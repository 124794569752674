<template>
  <div>
    <div class="mb-4 flex flex-col gap-4 px-4">
      <div class="flex flex-row items-center gap-3">
        <div class="flex w-4/12 items-center gap-2">
          <UiTooltip :width="200" name="booking_payment_tooltip">
            <template #activator>
              <UiIcon name="info-circle-filled" class="cursor-pointer text-primary-60" />
            </template>
            <template #content>
              <div>{{ $t('on_booking_payment_tooltip') }}</div>
            </template>
          </UiTooltip>
          <div class="text-subhead-4">{{ $t('total_down_payment') }}</div>
        </div>
        <div
          class="flex w-8/12 items-center gap-2"
          :class="{
            'pb-4':
              v && (useGetFieldErrors(v, ['down_payment_due_date']) || useGetFieldErrors(v, ['down_payment_amount'])),
          }"
        >
          <UiInputDatePicker
            v-model="modelValue!.down_payment_due_date"
            class="!w-auto"
            name="dateRange"
            :start-with-placeholder="!modelValue!.down_payment_due_date"
            :placeholder="$t('select_date')"
            :disabled="userCannotUpdateFields"
            :error="v && useGetFieldErrors(v, ['down_payment_due_date'])"
            compact
          />
          <UiInputTextField
            id="down_payment_amount"
            v-model="modelValue!.down_payment_amount"
            class="!w-auto"
            :placeholder="$t('add_down_payment_amount')"
            :error="v && useGetFieldErrors(v, ['down_payment_amount'])"
            name="down_payment"
            suffix="AED"
            type="number"
            disabled
            compact
          />
        </div>
      </div>
      <div class="flex flex-row items-center gap-3">
        <UiInputTextarea
          id="down_payment_note"
          v-model="modelValue!.down_payment_note"
          :placeholder="$t('add_a_note_to_down_payment')"
          name="down_payment_note"
          :disabled="userCannotUpdateFields || isClosedDealStage"
          :start-rows="1"
          compact
          limit-below
        />
      </div>
    </div>

    <div class="my-8 h-[1.5px] w-full bg-primary-30" />

    <div class="flex flex-col gap-4 px-4">
      <div class="flex flex-row items-center gap-3">
        <div class="flex w-4/12 items-center gap-2">
          <UiTooltip :width="200" name="booking_payment_tooltip">
            <template #activator>
              <UiIcon name="info-circle-filled" class="cursor-pointer text-primary-60" />
            </template>
            <template #content>
              <div>{{ $t('on_booking_payment_tooltip') }}</div>
            </template>
          </UiTooltip>
          <div class="text-subhead-3">
            <div>{{ $t('on_booking_payment') }}</div>
            <div class="text-caption-2">{{ $t('percent_from_unit_price') }}</div>
          </div>
        </div>
        <div class="flex w-8/12 items-center gap-2">
          <UiInputTextField
            id="down_payment_on_booking"
            v-model="modelValue!.down_payment_on_booking"
            name="down_payment_on_booking"
            suffix="AED"
            type="number"
            class="!w-auto"
            :disabled="userCannotUpdateFields || isClosedDealStage"
            compact
          />
          <h3 class="text-subhead-3">=</h3>
          <UiInputTextField
            id="down_payment_on_booking_percentage"
            v-model="bookingPaymentPercentage"
            name="down_payment_on_booking_percentage"
            suffix="%"
            type="percentage"
            class="!w-auto"
            :disabled="userCannotUpdateFields || isClosedDealStage"
            compact
          />
        </div>
      </div>
      <div class="flex flex-row items-center gap-3">
        <div class="flex w-4/12 items-center gap-2">
          <UiTooltip :width="200" name="booking_payment_tooltip">
            <template #activator>
              <UiIcon name="info-circle-filled" class="cursor-pointer text-primary-60" />
            </template>
            <template #content>
              <div>{{ $t('dld_fee_tooltip') }}</div>
            </template>
          </UiTooltip>
          <div class="text-subhead-3">
            <div>{{ $t('dld_fee') }}</div>
            <div>{{ $t('percent_from_unit_price') }}</div>
          </div>
        </div>
        <div class="flex w-8/12 items-center gap-2">
          <UiInputSelect
            ref="dld-fee-type"
            v-model="modelValue!.down_payment_dld_type"
            class="mr-5 !w-auto"
            name="dld-fee-type"
            :items="types"
            compact
          />
          <UiInputTextField
            id="down_payment_dld"
            v-model="modelValue!.down_payment_dld"
            name="down_payment_dld"
            suffix="AED"
            type="number"
            class="!w-auto"
            :disabled="userCannotUpdateFields || isClosedDealStage"
            compact
          />
          <h3 class="text-subhead-3">=</h3>
          <UiInputTextField
            id="down_payment_dld_percentage"
            v-model="dldFeePercentage"
            name="down_payment_dld_payment"
            suffix="%"
            type="percentage"
            class="!w-auto"
            :disabled="userCannotUpdateFields || isClosedDealStage"
            compact
          />
        </div>
      </div>
      <div class="flex flex-row items-center gap-3">
        <div class="flex w-4/12 items-center gap-2">
          <UiTooltip :width="200" name="booking_payment_tooltip">
            <template #activator>
              <UiIcon name="info-circle-filled" class="cursor-pointer text-primary-60" />
            </template>
            <template #content>
              <div>{{ $t('admin_fee_tooltip') }}</div>
            </template>
          </UiTooltip>
          <div class="text-subhead-3">{{ $t('admin_fee') }}</div>
        </div>
        <div class="flex w-8/12 items-center gap-2">
          <UiInputTextField
            id="down_payment_admin_fee"
            v-model="modelValue!.down_payment_admin_fee"
            name="down_payment_admin_fee"
            suffix="AED"
            type="number"
            class="mr-4 !w-auto"
            :disabled="userCannotUpdateFields || isClosedDealStage"
            compact
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import type { Deal, DealCreationUnitDTO } from '@/types'
import { DEFAULT_DEBOUNCE_INPUT_TIME } from '@/constants'

type Props = {
  v?: Validation
  userCannotUpdateFields: boolean
}

defineProps<Props>()

const ON_BOOKING = 1
const ON_HANDOVER = 2

const types = [
  {
    value: ON_BOOKING,
    text: 'On Booking',
  },
  {
    value: ON_HANDOVER,
    text: 'On Handover',
  },
]

const modelValue = defineModel<Deal | DealCreationUnitDTO>()

const bookingPaymentPercentage = computed<number>({
  get() {
    if (!modelValue.value?.down_payment_on_booking) return 0

    const percent = (Number(modelValue.value?.down_payment_on_booking) / Number(modelValue.value?.unit_price)) * 100

    return percent ? Number(percent.toFixed(2)) : 0
  },

  set(value: number) {
    const downPaymentAmount = (Number(modelValue.value?.unit_price) * value) / 100
    modelValue.value!.down_payment_on_booking = Number(downPaymentAmount.toFixed(2))
  },
})

const dldFeePercentage = ref(4)

const totalDownPayment = computed(
  () =>
    Number(modelValue.value?.down_payment_on_booking || 0) +
    Number(modelValue.value?.down_payment_admin_fee || 0) +
    (modelValue.value?.down_payment_dld_type === ON_BOOKING ? Number(modelValue.value?.down_payment_dld || 0) : 0)
)

watch(
  () => totalDownPayment.value,
  (value) => {
    if (modelValue.value) {
      modelValue.value.down_payment_amount = value
    }
  }
)

watch(
  () => modelValue.value?.unit_price,
  (value) => {
    if (value !== null && value !== undefined && dldFeePercentage.value && modelValue.value) {
      modelValue.value.down_payment_dld = value * (dldFeePercentage.value / 100)
    }
  }
)

watchDebounced(
  () => modelValue.value?.down_payment_dld,
  (value) => {
    if (value !== null && value !== undefined && modelValue.value) {
      dldFeePercentage.value = !modelValue.value.unit_price
        ? 100
        : Number(((value * 100) / modelValue.value.unit_price).toFixed(2))
    }
  },
  { immediate: true, debounce: DEFAULT_DEBOUNCE_INPUT_TIME }
)

watchDebounced(
  () => dldFeePercentage.value,
  (value) => {
    if (value && modelValue.value) {
      modelValue.value.down_payment_dld = Number((Number(modelValue.value.unit_price) * (value / 100)).toFixed(2))
    }
  },
  { debounce: DEFAULT_DEBOUNCE_INPUT_TIME }
)

const { isClosedDealStage } = useDealInfo(modelValue)
</script>

<style scoped></style>
