<template>
  <div class="mb-8">
    The total down payment includes multiple components: the booking payment, the DLD fee, and the admin fee.
    <ul>
      <li class="ml-5 list-disc">On booking payment and DLD fee are calculated as a percentage of the unit price.</li>
      <li class="ml-5 list-disc">The admin fee is a fixed amount.</li>
    </ul>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
